import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";

import { getProject } from "../../../../api/projects";
import { generateReport } from "../../../../api/report";
import Assignee from "../../../../assets/images/assignee-icon.svg";
import Attributes from "../../../../assets/images/attributes-icon.svg";
import Components from "../../../../assets/images/components-icon.svg";
import Lightbulb from "../../../../assets/images/lightbulb-icon.svg";
import MenuIcon from "../../../../assets/images/menu-icon.svg";
import PdfIcon from "../../../../assets/images/pdf-icon.png";
import { ReactComponent as PlusIconSmall } from "../../../../assets/images/plus-icon-small.svg";
import Process from "../../../../assets/images/process-blue.svg";
import Product from "../../../../assets/images/product-blue.svg";
import Service from "../../../../assets/images/service-blue.svg";
import Step from "../../../../assets/images/steps-icon.svg";
import { saveBufferInNewTab } from "../../../../common/downloads";
import { getInitials } from "../../../../helpers/getInitials";
import { getTranslation } from "../../../../helpers/getLanguage";
import store from "../../../../store";
import ProjectProgressBar from "../../../common/ProjectProgressBar";
import Tooltip from "../../../common/Tooltip";
import TranslateTemplate from "../../../common/TranslateTemplate";
import CardModal from "../../../dialogs/CardModal";
import ErrorMessageModal from "../../../dialogs/ErrorMessageModal";

const ProjectCard = ({
  project,
  history,
  handleModalOpen,
  handleDuplicate,
  handleDelete,
  handleArchive,
  isProjectArchived,
  disabled,
  canEdit,
  handleImport,
  userFeatures,
  order,
  setIsDragging,
  handleSelectProjectForTransfer,
}) => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState({
    openErrorModal: false,
    message: "",
    headerError: "",
  });

  const [{ isDragging }, dragRef] = useDrag({
    type: "projectCard",
    item: { id: project.id, order },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    setIsDragging && setIsDragging(isDragging);
  }, [isDragging]);

  const isArchived = isProjectArchived(project);

  const menuItems = [
    {
      value: "MENU_ITEM_EDIT_PROJECT",
      action: () => handleModalOpen("project", project),
      hidden: !canEdit,
    },
    {
      value: "MENU_ITEM_ADD_PROJECT_TO_FOLDER",
      action: () => handleSelectProjectForTransfer(project.id),
    },
    {
      value: "MENU_ITEM_EDIT_DUPLICATE",
      action: () => handleDuplicate(project),
    },
    {
      value: "MENU_ITEM_EDIT_DELETE",
      action: () => handleDelete(project),
    },
    {
      value: !isArchived ? "MENU_ITEM_EDIT_ARCHIVE" : "MENU_ITEM_EDIT_RESTORE",
      action: () => handleArchive(project, !isArchived),
    },
  ];

  userFeatures?.includes("import") &&
    menuItems.push({
      value: "MENU_ITEM_IMPORT_XLSX",
      action: () => handleImport(project),
    });

  const participantsCount = project.participants >= 0 ? project.participants : 1;

  const handleRedirectToProject = () => {
    if (!disabled) history.push(`/project/${project.id}`);
  };

  const redirectToReport = (e) => {
    e.stopPropagation();
    if (!disabled) history.push(`/project/${project.id}/report`);
  };

  const redirectToComponents = (e) => {
    e.stopPropagation();
    if (!disabled) history.push(`/project/${project.id}/components`);
  };

  const disabledClass = disabled ? " not-allowed" : "";

  const projectTypesWithImage = {
    process: { image: Process, content: "PROJECT_OPTIONS_PROCESS" },
    service: { image: Service, content: "PROJECT_OPTIONS_SERVICE" },
    product: { image: Product, content: "PROJECT_OPTIONS_PRODUCT" },
  };

  const getProjectType = (type) => {
    const image = projectTypesWithImage[type]?.image || "";
    const content = projectTypesWithImage[type]?.content || "";

    return (
      <div className="flex align-items-center justify-space-between">
        <img src={image} alt="" />
        <p className="project-title-name">{getTranslation(content)}</p>
      </div>
    );
  };

  const handlePrint = (e) => {
    e.stopPropagation();
    const params = { id: project.id, excludeDisabled: true };

    const onSuccess = (response) => {
      if (!isPending) {
        const ideasToPrint = [
          ...response.subtractions,
          ...response.detailsMatrix,
          ...response.replacements,
          ...response.multiplications,
          ...response.eurekaIdeas,
        ];

        const green = ideasToPrint.filter((row) => row.status === 3).sort((a, b) => b.rating - a.rating);
        const yellow = ideasToPrint.filter((row) => row.status === 1);
        const red = ideasToPrint.filter((row) => row.status === 2);

        const sortedIdeas = [...green, ...yellow, ...red];

        const components = response.components;
        const attributes = response.attributes;

        const ideas = sortedIdeas.map((idea, index) => {
          const remoteComponent = components.find((component) => component.id === idea?.idRemoteComponent);
          const replacingComponent = components.find((component) => component.id === idea?.idReplacingComponent);
          const multiplicationComponent = components.find(
            (component) => component.id === idea?.idMultiplicationComponent,
          );
          const attributeCol = attributes.find((attribute) => attribute.id === idea?.idAttributeCol);
          const attributeRow = attributes.find((attribute) => attribute.id === idea?.idAttributeRow);
          const firstComponent = components.find((component) => component.id === attributeCol?.componentId);
          const secondComponent = components.find((component) => component.id === attributeRow?.componentId);

          const issuesWithMessages = idea?.issues.map((issue) => {
            return {
              id: issue.id,
              userInfo: issue.createdByUser ? issue.createdByUser : `${issue.userInfo.name} ${issue.userInfo.lastName}`,
              comment: issue.comment,
              description: issue.description,
              ideaId: issue.ideaId,
              messages: [],
            };
          });

          return {
            index,
            id: idea.id,
            isReversed: idea.isReversed,
            remoteComponent: {
              id: remoteComponent?.id,
              name: remoteComponent?.name,
              locked: remoteComponent?.locked,
            },
            replacingComponent: {
              id: replacingComponent?.id,
              name: replacingComponent?.name,
              locked: replacingComponent?.locked,
            },
            multiplicationComponent: {
              id: multiplicationComponent?.id,
              name: multiplicationComponent?.name,
              locked: multiplicationComponent?.locked,
            },
            firstAttribute: { id: attributeCol?.id, name: attributeCol?.name },
            secondAttribute: { id: attributeRow?.id, name: attributeRow?.name },
            firstComponent: {
              id: firstComponent?.id,
              name: firstComponent?.name,
              locked: firstComponent?.locked,
            },
            secondComponent: {
              id: secondComponent?.id,
              name: secondComponent?.name,
              locked: secondComponent?.locked,
            },
            issuesWithMessages,
          };
        });

        const params = {
          ideas,
          projectId: project.id,
          teamId: project.teamId,
          language: store.getState().auth.userInfo.language || "en",
          isSharedIdeas: false,
        };

        const onSuccess = (data) => {
          setIsPending(false);
          saveBufferInNewTab(data, "pdf");
        };

        const onError = (error) => {
          console.log(error);
          setIsPending(false);
        };

        setIsPending(true);
        generateReport(params, onSuccess, onError);
      }
    };

    const onError = (error) =>
      setError({
        openErrorModal: true,
        message: error?.message ?? getTranslation("DEFAULT_REPORT_GENERATING_ERROR_MESSAGE"),
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });

    getProject({ params }, onSuccess, onError);
  };

  const closeErrorWindow = () => {
    setError({
      openErrorModal: false,
      message: "",
      headerError: "",
    });
  };

  return (
    <div
      className={`project flex-column ${isDragging && "isDragged"}` + (!disabled ? " pointer" : "")}
      onClick={handleRedirectToProject}
      ref={dragRef}
    >
      <div className="project-title-container flex">
        <TranslateTemplate as="div" className="project-title">
          {getProjectType(project.type)}
        </TranslateTemplate>

        <CardModal items={menuItems} offsetX={-160} disabled={disabled}>
          <button className={"menu-button" + disabledClass}>
            <img src={MenuIcon} alt=""></img>
          </button>
        </CardModal>
      </div>
      <div className="project-info">
        <Tooltip
          containerClass="project-info-tooltip"
          position="top"
          innerTextClass=""
          message={project.name}
          innerText={project.name}
          tooltipMessageClass="project-info-tooltip-message"
        />
      </div>

      <div className="flex align-center project-info-icons">
        <div className="flex icons-left">
          <div className="components flex align-center">
            <img src={project.type === "process" ? Step : Components} alt="" />
            <span>{project.cellsCount.components}</span>
          </div>

          <div className="components flex align-center">
            <img src={Attributes} alt=""></img>
            <span>{project.cellsCount.attributes}</span>
          </div>
        </div>

        {project.cellsCount.totalIdeas || project.cellsCount.components ? (
          <button className={"green-button project-button flex-center" + disabledClass} onClick={redirectToReport}>
            <img src={Lightbulb} alt=""></img>
            <span>
              {project.cellsCount.totalIdeas} {getTranslation("PAGE_ALL_PROJECTS_BUTTON_IDEAS")}
            </span>
          </button>
        ) : (
          <button className={"blue-button project-button flex" + disabledClass} onClick={redirectToComponents}>
            <span>{getTranslation("PAGE_ALL_PROJECTS_BUTTON_ADD")}</span>
            <PlusIconSmall />
          </button>
        )}

        <div className="flex icons-left">
          <div className="components flex align-center">
            <img src={Assignee} alt=""></img>
            <span>{participantsCount}</span>
          </div>
          <TranslateTemplate as="div" className="initials">
            {getInitials(project?.createdBy || "")}
          </TranslateTemplate>
        </div>
      </div>
      {project.isCreatedByDemo && (
        <div className="report-print-button-container">
          <div
            className="report-print-button"
            onClick={(e) => {
              handlePrint(e);
            }}
          >
            <img src={PdfIcon} alt="Print pdf" style={{ width: "30px", height: "30px", marginRight: "5px" }} />
            <span>{getTranslation("PROJECT_CARD_PRINT_REPORTS_SPAN")}</span>
          </div>
        </div>
      )}

      <ProjectProgressBar project={project.cellsCount} />

      {error.openErrorModal && (
        <ErrorMessageModal
          message={error.message}
          closeModalWindow={closeErrorWindow}
          textButton={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
          handleButton={closeErrorWindow}
          header={error.headerError}
        />
      )}
    </div>
  );
};

export default React.memo(ProjectCard);
