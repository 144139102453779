import { useCallback } from "react";

import { updateOrder } from "../../api/projects";

export const useUpdateSortOrderOfProject = ({ projectId, teamId, withAttributes = false }) => {
  const handleUpdateOrder = useCallback(
    (componentsOrder, attributesOrder) => {
      const params = {
        id: projectId,
        componentsOrder,
        teamId,
      };

      if (withAttributes && attributesOrder) {
        params.attributesOrder = attributesOrder;
      }

      updateOrder(params);
    },
    [projectId, teamId, withAttributes],
  );

  return { handleUpdateOrder };
};
