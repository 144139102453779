const fields = {
  email: { required: true, pattern: "email" },
  againNewPassword: { required: true, minLength: 6, maxLength: 30 },
  newPassword: { required: true, minLength: 6, maxLength: 30 },
  password: { required: true, minLength: 6, maxLength: 30 },
  name: { required: true },
  lastName: { required: true },
  confirmPassword: { required: true },
  promoCode: { required: false, pattern: "promoCode", maxLength: 30 },
  customer: { required: true },
  codeActivationEmail: { required: true, pattern: "email" },
  codeActivationCode: { required: true, pattern: "promoCode", minLength: 8, maxLength: 30 },
  url: { required: true, pattern: "url" },
  optionalUrl: { required: false, pattern: "url" },
};

const errorMessages = {
  required: "VALIDATE_ERROR_MESSAGE_REQUIRED",
  minLength: "VALIDATE_ERROR_MESSAGE_MIN_LENGTH",
  maxLength: "VALIDATE_ERROR_MESSAGE_MAX_LENGTH",
};

const patterns = {
  email: { value: /\S+@\S+\.\S+/, message: "VALIDATE_ERROR_MESSAGE_EMAIL" },
  promoCode: { value: /^[a-z,A-Z,0-9]+$|^$/, message: "VALIDATE_ERROR_MESSAGE_PROMO_CODE" },
  url: {
    value: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
    message: "VALIDATE_ERROR_MESSAGE_URL",
  },
};

export const validateField = (e, cb) => {
  const { name, value } = e.target || e;
  const config = fields[name];
  if (!config) {
    throw new Error(`Missing validation config for field '${name}'`);
  }

  if (config.required && !value.length) {
    cb && cb(e, errorMessages.required);
    return errorMessages.required;
  }

  if (config.minLength && value.length < config.minLength) {
    cb && cb(e, errorMessages.minLength);
    return errorMessages.minLength;
  }

  if (config.maxLength && value.length > config.maxLength) {
    cb && cb(e, errorMessages.maxLength);
    return errorMessages.maxLength;
  }

  if (config.pattern && value.length) {
    const match = value.match(patterns[config.pattern].value);
    const message = match ? "" : patterns[config.pattern].message;
    cb && cb(e, message);
    return message;
  }

  cb && cb(e, "");
  return "";
};
