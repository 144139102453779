const filterRowBySearch = (row, value) => {
  const searchValue = value.toLowerCase();
  if (row.createdBy.toLowerCase().includes(searchValue)) {
    return true;
  }
  if ((row.customer || "").toLowerCase().includes(searchValue)) {
    return true;
  }
  if (row.comments.toLowerCase().includes(searchValue)) {
    return true;
  }
  if (row.code.toLowerCase().includes(searchValue)) {
    return true;
  }
  return false;
};

const activationFilters = {
  empty: (codes) => codes.filter((code) => !code.usedLicenses),
  partly: (codes) => codes.filter((code) => code.usedLicenses && code.usedLicenses < code.totalLicenses),
  full: (codes) => codes.filter((code) => code.usedLicenses >= code.totalLicenses),
};

const discountFilters = {
  fullPrice: (codes) => codes.filter((code) => code.discount <= 0),
  discounted: (codes) => codes.filter((code) => code.discount),
  onlyDiscounted: (codes) => codes.filter((code) => code.discount > 0 && code.discount < 100 && code.price > 0),
  free: (codes) => codes.filter((code) => code.discount >= 100 || code.price === 0),
};

const durationFilters = {
  day: (codes) => codes.filter((code) => code.durationType === "day"),
  week: (codes) => codes.filter((code) => code.durationType === "week"),
  month: (codes) => codes.filter((code) => code.durationType === "month"),
  year: (codes) => codes.filter((code) => code.durationType === "year"),
};

const isActiveFilters = {
  yes: (codes) => codes.filter((code) => code.isActive),
  no: (codes) => codes.filter((code) => !code.isActive),
};

const generationFilters = {
  admin: (codes) => codes.filter((code) => !code.generatedBySystem),
  system: (codes) => codes.filter((code) => code.generatedBySystem),
};

const archivedFilters = {
  unarchived: (codes) => codes.filter((code) => !code.isArchived),
  archived: (codes) => codes.filter((code) => code.isArchived),
};

const promoCodeFilter = {
  search: (codes, value) => codes.filter((row) => filterRowBySearch(row, value)),
  activation: (codes, value) => activationFilters[value](codes),
  discounts: (codes, value) => discountFilters[value](codes),
  durationType: (codes, value) => durationFilters[value](codes),
  isActive: (codes, value) => isActiveFilters[value](codes),
  generation: (codes, value) => generationFilters[value](codes),
  archived: (codes, value) => archivedFilters[value](codes),
};

export const filterPromoCodes = (codes, filter) => {
  let filteredCodes = codes;
  for (let key in filter) {
    const value = filter[key];
    if (value) {
      filteredCodes = promoCodeFilter[key](filteredCodes, value);
    }
  }

  return filteredCodes;
};

const compareValues = (a = "", b = "") => {
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
};

const promoCodeSort = {
  date: (codes) => codes.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
  createdBy: (codes) => codes.slice().sort((a, b) => (a.createdBy.toLowerCase() > b.createdBy.toLowerCase() ? 1 : -1)),
  customer: (codes) => codes.slice().sort((a, b) => compareValues(a.customer, b.customer)),
  licenses: (codes) => codes.slice().sort((a, b) => a.totalLicenses - b.totalLicenses),
  durationCount: (codes) => codes.slice().sort((a, b) => a.durationCount - b.durationCount),
  durationType: (codes) => codes.slice().sort((a, b) => compareValues(a.durationType, b.durationType)),
  price: (codes) => codes.slice().sort((a, b) => a.price - b.price),
  discount: (codes) => codes.slice().sort((a, b) => a.discount - b.discount),
  comments: (codes) => codes.slice().sort((a, b) => compareValues(a.comments, b.comments)),
  code: (codes) => codes.slice().sort((a, b) => compareValues(a.code, b.code)),
  isActive: (codes) => codes.slice().sort((a, b) => compareValues(a.isActive, b.isActive)),
};

export const sortPromoCodes = (codes, sort) => {
  const sortedCodes = promoCodeSort[sort.field](codes);
  if (!sort.asc) {
    sortedCodes.reverse();
  }

  return sortedCodes;
};

export const sortUsers = (codes, sort) => {
  const sortedCodes = usersFilter[sort.field](codes);
  if (!sort.asc) {
    sortedCodes.reverse();
  }

  return sortedCodes;
};

const filterUsersBySearch = (row, value) => {
  const searchValue = value.toLowerCase();
  if (row.email.toLowerCase().includes(searchValue)) {
    return true;
  }
  if (row.name.toLowerCase().includes(searchValue)) {
    return true;
  }
  if ((row.lastName || "").toLowerCase().includes(searchValue)) {
    return true;
  }
  return false;
};

export const filterUsers = (users, filter) => {
  let filteredUsers = users;
  for (let key in filter) {
    const value = filter[key];
    if (value) {
      filteredUsers = usersFilter[key](filteredUsers, value);
    }
  }

  return filteredUsers;
};

const usersFilter = {
  search: (users, value) => users.filter((row) => filterUsersBySearch(row, value)),
  date: (users) => users.slice().sort((a, b) => (new Date(a.date).getTime() >= new Date(b.date).getTime() ? 1 : -1)),
  fullName: (codes) =>
    codes.slice().sort((a, b) => compareValues(`${a.name} ${a.lastName}`, `${b.name} ${b.lastName}`)),
  email: (codes) => codes.slice().sort((a, b) => compareValues(`${a.email}`, `${b.email}`)),
  subscription: (users) => [
    ...users.slice().filter((user) => user.activeSubscription),
    ...users.slice().filter((user) => !user.activeSubscription),
  ],
};
