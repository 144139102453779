import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import Dialog from "../Dialog";
import "./styles.css";

const ConfirmationModal = ({
  disabled = false,
  closeDialog,
  message,
  children,
  onConfirm,
  closeOnClickOutside = true,
  closeOnEscape = true,
  buttonText = getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT"),
  hideCloseIcon = false,
  messageWidth = 0,
  autoFocus = false,
  messageHeight = 0,
  className,
  textContainerClass,
}) => {
  const handleCloseDialog = () => {
    closeDialog && closeDialog();
  };

  const popupTextStyles = {};
  if (messageWidth) {
    popupTextStyles.width = messageWidth;
  }
  if (messageHeight) {
    popupTextStyles.minHeight = messageHeight;
  }

  return (
    <Dialog closeDialog={closeDialog} closeOnClickOutside={closeOnClickOutside} closeOnEscape={closeOnEscape}>
      <div className={`delete-popup flex-column ${className}`}>
        <div className="delete-popup-close flex width-100">
          {!hideCloseIcon && <img src={CloseIcon} alt="" className="pointer" onClick={handleCloseDialog} />}
        </div>

        <div className="flex-center height-100">
          <div className="flex-column align-center">
            {!children ? (
              <span className={`delete-popup-text ${textContainerClass}`} style={popupTextStyles}>
                {getTranslation(message)}
              </span>
            ) : (
              children
            )}
            <button
              className="delete-popup-button"
              autoFocus={autoFocus}
              disabled={disabled}
              onClick={() => onConfirm()}
            >
              <span>{getTranslation(buttonText)}</span>
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
