const redirectToProfile = (error) => {
  const isAccessDenied = error.response && error.response.data.code === "30650";
  if (isAccessDenied) {
    console.error(error);
    window.location.href = `${window.location.origin}/profile?tab=1&modal=subscription`;
    return true;
  }
  return false;
};

export const handleSuccess = (response, callback) => {
  if (callback) {
    callback(response.data.data);
  }
};

export const handleError = (error, callback) => {
  if (redirectToProfile(error)) {
    return;
  }

  if (callback) {
    callback(error);
  } else {
    console.error(error);
  }
};
