import { useEffect, useState } from "react";

export default ({ emitEvent, receiveEvent, socket, dependencies }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.emit(emitEvent);
    socket.on(receiveEvent, setData);

    return () => {
      socket.off(receiveEvent, setData);
    };
  }, [socket, ...dependencies]);

  return data;
};
