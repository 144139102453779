import _ from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { sortableContainer } from "react-sortable-hoc";

import { setPreference } from "../../actions/preferencesActions";
import {
  getFullProjectInfo,
  setComponentsHasAIResponsesCache,
  setCurrentComponents,
  setGeneratedOpenAIComponents,
  setGeneratedOpenAIRequests,
  setLastWordsToGeneratedOpenAIComponents,
  setProjectHasAIResponsesCache,
  setStepsOpenAI,
} from "../../actions/projectActions";
import { suggestAttributes, suggestComponents, suggestSteps } from "../../api/ai";
import { copyAttribute, deleteAttribute, disableAttribute, duplicateAttribute } from "../../api/attributes";
import { deleteComponent, disableComponent, duplicateComponent } from "../../api/components";
import { ReactComponent as AIGenerateIcon } from "../../assets/images/ai-generate-icon.svg";
import useContainerHeight from "../../common/useContainerHeight";
import { getTranslation } from "../../helpers/getLanguage";
import { useUpdateSortOrderOfProject } from "../../hooks/projects/useUpdateSortOrderOfProject";
import CustomScrollbar from "../common/CustomScrollbar";
import Loader from "../common/Loader";
import MessageShowNotFound from "../common/MessageShowNotFound";
import ProjectTopBar from "../common/ProjectTopBar";
import Tooltip from "../common/Tooltip";
import AttributeAddModal from "../dialogs/AttributeAddModal";
import BuyMoreAICreditsModal from "../dialogs/BuyMoreAICreditsModal";
import CardModal from "../dialogs/CardModal";
import CheckoutModal from "../dialogs/CheckoutModal";
import ComponentAddModal from "../dialogs/ComponentAddModal";
import ConfirmationModal from "../dialogs/ConfirmationModal";
import WarningModal from "../dialogs/WarningModal";
import ComponentCards from "./ComponentCards";
import ComponentsDashboardHeader from "./ComponentsDashboardHeader";
import GenerateAttributesModal from "./GenerateAttributesModal";
import GenerateComponentsModal from "./GenerateComponentsModal";
import "./styles.css";
import WarningNotTokensModal from "./WarningNotTokensModal";

const labels = ["PAGE_COMPONENT_LABEL_EXPANDED", "PAGE_COMPONENT_LABEL_COMPACT"];
const sortLabel = "PAGE_COMPONENT_SORT_LABEL";

const initialState = {
  search: "",
};

const modalsInitialState = {
  component: false,
  attribute: false,
  delete: false,
  attributeCopyModal: false,
  generateAttributes: false,
  generateComponents: false,
  openAISubscription: false,
  deleteWarning: false,
  openBuyModal: false,
};

const projectInitialState = {
  product: {},
  attributes: [],
  components: [],
};

const popupMessages = {
  component: "CONFIRM_MODAL_DELETE_COMPONENT",
  attribute: "CONFIRM_MODAL_DELETE_ATTRIBUTE",
  step: "CONFIRM_MODAL_DELETE_STEP",
};

const initialSortOrder = { attributes: {}, components: [] };
const initialAttributeToCopy = { id: "", sourceId: "", destinationId: "", index: 0 };

const elementTypes = {
  attribute: "attribute",
  component: "component",
};

const initialSuggestedMode = {
  mode: false,
  name: "",
  attribute: {
    component: {
      name: "",
      element: null,
      componentId: "",
      internal: false,
      disabled: false,
    },
    name: "",
  },
};

const initialSuggestedLoading = {
  component: false,
  attribute: false,
};

const initialPaymentInfo = {
  intervalCount: 1,
  quantity: 1,
  key: "",
  interval: null,
  openAIRequests: null,
  total: null,
  promoCode: "",
  promoCodeOpenAI: "",
};

const ComponentsDashboard = ({
  match,
  errors,
  product,
  attributes,
  components,
  auth,
  compact,
  isSorted,
  history,
  isLoading,
  getProjectInfo: getComponentsData,
  setPreference,
  openAI,
  setLastWordsToGeneratedOpenAIComponents,
  setGeneratedOpenAIComponents,
  numberOpenAIRequests,
  subtractions,
  replacements,
  multiplications,
  detailsMatrix,
  setGeneratedOpenAIRequests,
  setCurrentComponents,
  language,
  setProjectHasAIResponsesCache,
  setComponentsHasAIResponsesCache,
}) => {
  const projectId = match.params.projectId;
  const [inputValues, setInputValues] = useState(initialState);
  const [projectData, setProjectData] = useState(projectInitialState);
  const [filteredProjectData, setFilteredProjectData] = useState();
  const [modalOpen, setModalOpen] = useState(modalsInitialState);
  const [selectedElement, setSelectedElement] = useState({});
  const [elementToDelete, setElementToDelete] = useState();
  const [editMode, setEditMode] = useState(false);
  const containerRef = useRef(null);
  const dragMode = useRef(false);
  const [containerHeight, updateHeight] = useContainerHeight(containerRef, 20);
  const [sortOrder, setSortOrder] = useState(null);
  const [attributeToCopy, setAttributeToCopy] = useState(initialAttributeToCopy);
  const [pendingOrderChange, setPendingOrderChange] = useState([]);
  const [lastClickPosition, setLastClickPosition] = useState({ clientX: 0, clientY: 0 });
  const [paymentInfo, setPaymentInfo] = useState(initialPaymentInfo);

  const queryParams = queryString.parse(history.location.search);
  const [attributeId, setAttributeId] = useState(queryParams.attribute);
  const [componentId, setComponentId] = useState(queryParams.component);

  const [suggestedComponents, setSuggestedComponents] = useState([]);
  const [suggestedAttributes, setSuggestedAttributes] = useState([]);

  const suggestedComponentsNameRef = useRef("");
  const [suggestedMode, setSuggestedMode] = useState(initialSuggestedMode);
  const [suggestedLoading, setSuggestedLoading] = useState(initialSuggestedLoading);
  const [suggestingVisible, setSuggestingVisible] = useState(false);
  const [generationError, setGenerationError] = useState("");
  const [isCustomerState, setIsCustomerState] = useState(projectData?.components[0]?.isCustomer);
  const [processSteps, setProcessStep] = useState();
  const [currentComponentsState, setCurrentComponentsState] = useState();

  const { handleUpdateOrder } = useUpdateSortOrderOfProject({
    projectId,
    teamId: product.teamId,
    withAttributes: true,
  });

  useEffect(() => {
    if (numberOpenAIRequests === null) {
      return;
    }
    setModalOpen({ ...modalOpen, openAISubscription: !numberOpenAIRequests });
  }, []);

  useEffect(() => {
    if (queryParams.attribute !== attributeId || componentId !== queryParams.component) {
      let isAttributeIdValid = true;

      const foundAttribute = attributes.find((item) => item.id === attributeId);

      if (foundAttribute && foundAttribute.componentId !== componentId) {
        isAttributeIdValid = false;
        setAttributeId("");
      }

      const search =
        `?component=${componentId}` + (attributeId && isAttributeIdValid ? `&attribute=${attributeId}` : "");

      history.replace({ pathname: "components", search });
    }
  }, [attributeId, componentId]);

  useEffect(() => {
    getComponentsData(projectId);
  }, [getComponentsData, projectId]);

  useEffect(() => {
    if (product.id && sortOrder) {
      updateProjectData(sortOrder);
    }
  }, [sortOrder, product.id]);

  const updateProjectData = (sort) => {
    const {
      attributes: sortedAttributes,
      internalComponents,
      externalComponents,
    } = sortProjectData(attributes, components, sort);

    setProjectData((projectData) => ({
      ...projectData,
      product: product,
      attributes: sortedAttributes,
      components: [...internalComponents, ...externalComponents],
    }));
    const firstValue = suggestedComponentsNameRef.current || product?.name || "";
    suggestedComponentsNameRef.current = firstValue;
    updateHeight();
  };

  useEffect(() => {
    if (product.id) {
      const sort = product.sortOrder || initialSortOrder;
      setSortOrder({ attributes: sort.attributes || {}, components: sort.components || [] });
    }
  }, [product]);

  useEffect(() => {
    const filterComponents = () => {
      const searchString = inputValues.search.toLowerCase();
      const filteredAttributes = projectData.attributes.filter(
        (attribute) =>
          attribute.name.toLowerCase().includes(searchString) ||
          attribute.description.toLowerCase().includes(searchString),
      );

      const filteredComponents = projectData.components.filter(
        (component) =>
          component.name.toLowerCase().includes(searchString) ||
          filteredAttributes.find((attribute) => attribute.componentId === component.id),
      );

      const allAttributes = projectData.attributes.filter((attribute) =>
        filteredComponents.find((component) => attribute.componentId === component.id),
      );

      setFilteredProjectData({
        ...projectData,
        components: filteredComponents,
        attributes: allAttributes,
      });
    };

    if (projectData) {
      if (!inputValues.search) {
        setFilteredProjectData({ ...projectData });
        setIsCustomerState(projectData?.components[0]?.isCustomer ? projectData?.components[0]?.isCustomer : false);
      } else {
        filterComponents();
      }
    }
  }, [inputValues.search, projectData]);

  const clearGenerationError = useCallback(() => setGenerationError(""), []);

  const sortProjectData = (unsortedAttributes, unsortedComponents, sort) => {
    const attributesOrder = sort.attributes || {};
    const componentsOrder = sort.components || [];
    const attributes = unsortedAttributes.slice();
    const clonedAttributesOrder = _.cloneDeep(attributesOrder);
    let clonedComponentsOrder = Array.from(new Set(_.cloneDeep(componentsOrder || [])));
    const newSortOrder = _.cloneDeep(sort);

    let shouldUpdate = false;
    const hasPendingChange = pendingOrderChange.length > 0;
    if (hasPendingChange) {
      shouldUpdate = true;

      for (let value of pendingOrderChange) {
        const destinationIds = clonedAttributesOrder[value.destinationId] || [];
        const newDestinationIds = [
          ...destinationIds.slice(0, value.index),
          value.id,
          ...destinationIds.slice(value.index),
        ];
        clonedAttributesOrder[value.destinationId] = newDestinationIds;
      }

      setPendingOrderChange([]);
    }

    if (Object.keys(clonedAttributesOrder).length) {
      for (let key in clonedAttributesOrder) {
        const attributeIds = clonedAttributesOrder[key] || [];
        if (attributeIds.length > 1) {
          clonedAttributesOrder[key] = Array.from(new Set(attributeIds));
        }

        let offset = 0;
        let hasErrors = false;
        for (let index in attributeIds) {
          const currentOffset = offset;
          const foundAttribute = attributes.find(
            (attribute) => attribute.id === attributeIds[index - currentOffset] && attribute.componentId === key,
          );

          if (foundAttribute) {
            foundAttribute.sortOrder = +index - offset;
          } else {
            clonedAttributesOrder[key][index] = "";
            hasErrors = true;
            offset++;
          }
        }

        hasErrors && (clonedAttributesOrder[key] = clonedAttributesOrder[key].filter((id) => id));
      }

      let unorderedAttribute = attributes.find((attribute) => typeof attribute.sortOrder !== "number");

      while (unorderedAttribute) {
        const attributeIds = clonedAttributesOrder[unorderedAttribute.componentId];
        const attributesLength = (attributeIds || []).length;

        clonedAttributesOrder[unorderedAttribute.componentId] = [...(attributeIds || []), unorderedAttribute.id];

        unorderedAttribute.sortOrder = attributesLength;
        unorderedAttribute = attributes.find((attribute) => typeof attribute.sortOrder !== "number");
      }
      const isOrderChanged = !_.isEqual(clonedAttributesOrder, sortOrder.attributes);
      if (isOrderChanged) {
        newSortOrder.attributes = clonedAttributesOrder;
        shouldUpdate = true;
      }

      attributes.sort((a, b) => a.sortOrder - b.sortOrder);
    } else {
      if (attributes.length) {
        attributes.sort((a, b) => (a.date > b.date ? 1 : -1));
        const attributeIds = attributes.map((attribute) => ({
          componentId: attribute.componentId,
          attributeId: attribute.id,
        }));

        const groupedIds = _.groupBy(attributeIds, "componentId");
        for (let key in groupedIds) {
          groupedIds[key] = groupedIds[key].map((row) => row.attributeId);
        }

        let unorderedAttribute = attributes.find((attribute) => typeof attribute.sortOrder !== "number");
        while (unorderedAttribute) {
          const attributeIds = clonedAttributesOrder[unorderedAttribute.componentId];
          const attributesLength = (attributeIds || []).length;
          clonedAttributesOrder[unorderedAttribute.componentId] = [...(attributeIds || []), unorderedAttribute.id];

          unorderedAttribute.sortOrder = attributesLength;
          unorderedAttribute = attributes.find((attribute) => typeof attribute.sortOrder !== "number");
        }

        newSortOrder.attributes = groupedIds;
        shouldUpdate = true;
      } else {
        newSortOrder.attributes = {};
      }
    }

    let internalComponents, externalComponents;
    let components = unsortedComponents.slice();

    if (clonedComponentsOrder.length) {
      const newComponentsOrder = [];

      const internalClonedComponents = components.filter((component) => component.internal && !component.locked);
      const internalLocked = components.filter((component) => component.internal && component.locked);

      const externalClonedComponents = components.filter((component) => !component.internal && !component.locked);
      const externalLocked = components.filter((component) => !component.internal && component.locked);

      const updateComponentsOrder = (baseOffset = 0, components) => {
        let offset = 0;
        for (let index in clonedComponentsOrder) {
          const currentOrder = clonedComponentsOrder[index];
          const foundComponent = components.find((component) => component.id === currentOrder);
          if (foundComponent) {
            foundComponent.sortOrder = +index - offset + baseOffset;
            newComponentsOrder.push(foundComponent.id);
          } else {
            offset++;
          }
        }
      };

      const updateUnorderedComponents = (isInternal) => {
        let unorderedComponent = components.find(
          (component) => typeof component.sortOrder !== "number" && component.internal === isInternal,
        );

        while (unorderedComponent) {
          unorderedComponent.sortOrder = newComponentsOrder.length;
          newComponentsOrder.push(unorderedComponent.id);

          unorderedComponent = components.find(
            (component) => typeof component.sortOrder !== "number" && component.internal === isInternal,
          );
        }
      };

      updateComponentsOrder(0, internalLocked);
      updateComponentsOrder(newComponentsOrder.length, internalClonedComponents);
      updateUnorderedComponents(true);

      updateComponentsOrder(newComponentsOrder.length, externalLocked);
      updateComponentsOrder(newComponentsOrder.length, externalClonedComponents);
      updateUnorderedComponents(false);

      const isComponentsOrderChanged = !_.isEqual(newComponentsOrder, sortOrder.components);
      if (isComponentsOrderChanged) {
        newSortOrder.components = newComponentsOrder;
        clonedComponentsOrder = newComponentsOrder;
        shouldUpdate = true;
      }

      components.sort((a, b) => a.sortOrder - b.sortOrder);

      internalComponents = components.filter((component) => component.internal);
      externalComponents = components.filter((component) => !component.internal);
    } else {
      if (unsortedComponents.length) {
        const lockedInternalComponent = unsortedComponents.find((component) => component.locked && component.internal);
        const lockedExternalComponent = unsortedComponents.find((component) => component.locked && !component.internal);

        components = components.filter((component) => !component.locked).sort((a, b) => (a.date > b.date ? 1 : -1));

        internalComponents = components.filter((component) => component.internal);
        externalComponents = components.filter((component) => !component.internal);

        lockedInternalComponent && internalComponents.unshift(lockedInternalComponent);
        lockedExternalComponent && externalComponents.unshift(lockedExternalComponent);

        clonedComponentsOrder = [...internalComponents, ...externalComponents].map((component) => component.id);

        newSortOrder.components = clonedComponentsOrder;
        shouldUpdate = true;
      } else {
        newSortOrder.components = [];

        internalComponents = [];
        externalComponents = [];
      }
    }

    shouldUpdate && handleUpdateOrder(clonedComponentsOrder, clonedAttributesOrder);
    !_.isEqual(newSortOrder, sortOrder) && setSortOrder(newSortOrder);

    return {
      attributes,
      internalComponents,
      externalComponents,
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleCloseModal = () => {
    setEditMode(false);
    setSuggestingVisible(false);
    setSelectedElement({});
    setModalOpen({
      component: false,
      attribute: false,
      delete: false,
      generateAttributes: false,
      openAISubscription: false,
    });

    setSuggestedComponents([]);
    setSuggestedMode(initialSuggestedMode);
    setSuggestedAttributes([]);
  };

  const handleModalOpen = (name, element, edit = false) => {
    element && setSelectedElement(element);
    edit && setEditMode(true);

    setModalOpen({ ...modalOpen, [name]: true });
  };

  const handleDelete = (type, id) => {
    const ideals = [...replacements, ...subtractions, ...multiplications, ...detailsMatrix];
    setElementToDelete({ type, id });

    if (
      ideals.some((idea) => {
        let componentId = null;

        for (let key in idea) {
          if (/id+[-.\w]+Component/g.test(key)) {
            componentId = idea[key];
            break;
          }
        }

        return id === componentId;
      })
    ) {
      return setModalOpen({ ...modalOpen, deleteWarning: true });
    }

    setModalOpen({ ...modalOpen, delete: true });
  };

  const typeExists = (type) => {
    const isPresent = Object.values(elementTypes).includes(type);

    if (!isPresent) {
      console.warn("Cannot find the type ", type);
    }

    return isPresent;
  };

  const deleteItem = (element) => {
    const { type } = element;
    if (!typeExists(type)) {
      return;
    }

    const params = { id: element.id, teamId: product.teamId || "" };

    if (type === elementTypes.attribute) {
      deleteAttribute(params, () => getComponentsData(projectId));
    }
    if (type === elementTypes.component) {
      deleteComponent(params, () => getComponentsData(projectId));
    }

    setModalOpen({ ...modalOpen, delete: false });

    setElementToDelete(null);
  };

  const handleDuplicate = (type, element) => {
    if (!typeExists(type)) {
      return;
    }

    const params = {
      id: element.id,
      userId: auth.user.id,
      teamId: product.teamId || "",
    };

    if (type === elementTypes.attribute) {
      duplicateAttribute(params, () => getComponentsData(projectId));
    }
    if (type === elementTypes.component) {
      duplicateComponent(params, () => getComponentsData(projectId));
    }
  };

  const handleDisable = (type, element) => {
    if (!typeExists(type)) {
      return;
    }

    const params = { id: element.id, disabled: !element.disabled, teamId: product.teamId || "" };

    if (type === elementTypes.attribute) {
      disableAttribute(params, () => getComponentsData(projectId));
    }
    if (type === elementTypes.component) {
      disableComponent(params, () => getComponentsData(projectId));
    }
  };

  const containerStyle = {};
  containerHeight && (containerStyle.height = containerHeight);

  const handleCopyAttribute = (copy = true) => {
    const { sourceId, destinationId, id, index } = attributeToCopy;

    const params = {
      id,
      projectId,
      teamId: product.teamId,
      sourceId,
      destinationId,
      copy,
    };

    const onSuccess = (response) => {
      setPendingOrderChange([...pendingOrderChange, { id: response.id, index, destinationId }]);
      setAttributeToCopy(initialAttributeToCopy);
      getComponentsData(projectId);
      setModalOpen({ ...modalOpen, attributeCopyModal: false });
    };

    const onError = (error) => {
      console.error(error);
      setAttributeToCopy(initialAttributeToCopy);
      setModalOpen({ ...modalOpen, attributeCopyModal: false });
    };

    copyAttribute(params, onSuccess, onError);
  };

  const attributeMenuItems = [
    {
      value: "PAGE_COMPONENT_ATTRIBUTE_MENU_ITEM_COPY",
      action: () => handleCopyAttribute(true),
    },
    {
      value: "PAGE_COMPONENT_ATTRIBUTE_MENU_ITEM_MOVE",
      action: () => handleCopyAttribute(false),
    },
  ];

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragStart = () => {
    dragMode.current = true;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!result.destination) {
      return;
    }
    const isSameComponent = destination.droppableId === source.droppableId;
    const isSameIndex = destination.index === source.index;
    if (isSameComponent && isSameIndex) {
      return;
    }

    const destinationId = destination.droppableId;

    if (isSameComponent && !isSameIndex && !isSorted) {
      const reorderedAttributeIds = reorder(sortOrder.attributes[destinationId] || [], source.index, destination.index);

      updateProjectData({
        ...sortOrder,
        attributes: { ...sortOrder.attributes, [destinationId]: reorderedAttributeIds },
      });
    }

    if (!isSameComponent) {
      setAttributeToCopy({
        id: result.draggableId,
        sourceId: source.droppableId,
        destinationId: destination.droppableId,
        index: destination.index,
      });

      const elem = document.querySelector(`[data-rbd-droppable-id="${destination.droppableId}"]`);
      const rect = elem.parentElement.getBoundingClientRect();
      const position = { left: rect.x + rect.width / 2, top: rect.y + rect.height / 2 };
      if (position.top < 0) {
        position.top = 75;
      }
      if (position.top > window.innerHeight) {
        position.top = window.innerHeight - 75;
      }

      setLastClickPosition({ clientX: position.left, clientY: position.top });

      setModalOpen({ ...modalOpen, attributeCopyModal: true });
    }

    dragMode.current = false;
  };

  const onSortEnd = (result) => {
    const newSortOrder = { ...sortOrder };
    const componentsOrder = newSortOrder.components || [];

    newSortOrder.components = reorder(componentsOrder, result.oldIndex, result.newIndex);
    updateProjectData(newSortOrder);
  };

  const SortableCards = sortableContainer(({ children }) => {
    return (
      <div style={{ height: "73vh" }} className="flex width-100 height-100 flex-wrap">
        {children}
      </div>
    );
  });

  const closeAttributeCopyModal = useCallback(() => {
    setModalOpen((modalOpen) => ({ ...modalOpen, attributeCopyModal: false }));
  }, []);

  const isHorizontalScroll = !["product", "service"].includes(product.type) && !compact;

  const componentsCards = useCallback(
    () => (
      <div className="mt-10" ref={containerRef} style={containerStyle}>
        <CustomScrollbar
          right={-5}
          dependencies={[inputValues, compact, filteredProjectData, containerHeight, suggestedComponents]}
          isHorizontalScroll={isHorizontalScroll}
          className="components-cards-wrapper flex width-100"
        >
          <SortableCards onSortEnd={onSortEnd} axis="xy" useDragHandle distance={20}>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <div className={`flex ${!isHorizontalScroll && "flex-wrap"}`}>
                <ComponentCards
                  isProcessType={product.type === "process"}
                  isHorizontalScroll={isHorizontalScroll}
                  inputValues={inputValues}
                  compact={compact}
                  isSorted={isSorted}
                  projectData={filteredProjectData}
                  handleModalOpen={handleModalOpen}
                  handleDelete={handleDelete}
                  handleDuplicate={handleDuplicate}
                  handleDisable={handleDisable}
                  history={history}
                  queryParams={queryParams}
                  setAttributeId={setAttributeId}
                  setComponentId={setComponentId}
                  dragMode={dragMode}
                  {...{
                    suggestedMode,
                    setSuggestedMode,
                    handleSuggestingAttributes,
                    numberOpenAIRequests,
                  }}
                />
              </div>
            </DragDropContext>
          </SortableCards>
        </CustomScrollbar>
      </div>
    ),
    [
      projectData,
      attributes,
      components,
      product,
      inputValues,
      compact,
      filteredProjectData,
      containerHeight,
      suggestedComponents,
      isSorted,
    ],
  );

  const hasFilteredData = filteredProjectData && filteredProjectData.components.length > 0;
  const showNotFoundError = errors && errors.response && errors.response.status === 404;

  const handleSuggestingComponents = (language, nameOverride) => {
    const onSuccess = ({ internal, external, numberRequests }) => {
      setGeneratedOpenAIComponents({ internal, external });
      setGeneratedOpenAIRequests(numberRequests);

      setSuggestedLoading(initialSuggestedLoading);
      updateHeight();

      if (product.typeName === (nameOverride ?? suggestedComponentsNameRef.current)) {
        setProjectHasAIResponsesCache(true);
      }
    };

    setSuggestedLoading({ ...suggestedLoading, component: true });

    suggestComponents(nameOverride ?? suggestedComponentsNameRef.current, product.id, language, onSuccess, (err) => {
      setGenerationError(err?.response?.data?.message || err?.message);
      setSuggestedLoading(initialSuggestedLoading);
    });
  };

  const handleSuggestingSteps = (nameOverride, isCustomer, language) => {
    const onSuccess = ({ parsedChoices, numberRequests }) => {
      setCurrentComponents(components.filter((item) => !item.id.includes("generate")));
      setGeneratedOpenAIComponents({
        steps: parsedChoices.map((value, index) => ({ name: value, id: index + "generate", internal: true })),
      });
      setGeneratedOpenAIRequests(numberRequests);

      setSuggestedLoading(initialSuggestedLoading);
      updateHeight();
    };

    setSuggestedLoading({ ...suggestedLoading, component: true });

    suggestSteps(
      nameOverride ?? suggestedComponentsNameRef.current,
      product.id,
      isCustomer,
      language,
      onSuccess,
      (err) => {
        setGenerationError(err?.response?.data?.message || err?.message);
        setSuggestedLoading(initialSuggestedLoading);
      },
    );
  };

  const handleSuggestingAttributes = (componentName, componentId, languagePrompt) => {
    const onSuccess = ({ parsedChoices, numberRequests }) => {
      setSuggestedAttributes(parsedChoices);
      setSuggestedLoading(initialSuggestedLoading);
      setGeneratedOpenAIRequests(numberRequests);
      setComponentsHasAIResponsesCache(componentId);
    };

    setSuggestedLoading({ ...suggestedLoading, attribute: true });

    suggestAttributes(
      {
        projectName: product.typeName ? product.typeName : product.name,
        componentName,
        componentId,
        projectType: product.type,
        language: languagePrompt,
      },
      onSuccess,
      (err) => {
        setGenerationError(err?.response?.data?.message || err?.message);
        setSuggestedLoading(initialSuggestedLoading);
      },
    );
  };

  const handleCreateSuggestedComponent = (name) => () => {
    setSuggestedMode({ mode: true, name });
    setModalOpen((modalOpen) => ({
      ...modalOpen,
      component: true,
      attribute: false,
      attributeCopyModal: false,
      delete: false,
      generateAttributes: false,
      generateComponents: false,
      openAISubscription: false,
    }));
  };

  const handleCreateSuggestedAttribute = (name) => () => {
    setSelectedElement({ ...selectedElement, ...suggestedMode?.attribute?.component });

    setSuggestedMode({ ...suggestedMode, mode: true, attribute: { ...suggestedMode?.attribute, name } });
    setModalOpen((modalOpen) => ({
      ...modalOpen,
      component: false,
      attribute: true,
      attributeCopyModal: false,
      delete: false,
      generateAttributes: false,
      generateComponents: false,
      openAISubscription: false,
    }));
  };

  const isOpenGenerateComponentsModal = modalOpen.generateComponents && !generationError && !modalOpen.openBuyModal;
  const isOpenGenerateAttributesModal = modalOpen.generateAttributes && !generationError;

  const labelsPerson = ["LABEL_CUSTOMER", "LABEL_COMPANY"];

  const labelsLocations = [
    <Tooltip
      message={getTranslation("TOOLTIP_MESSAGE_INTERNAL_COMPONENT")}
      innerText={getTranslation("LABEL_INTERNAL")}
      containerClass=""
    />,
    <Tooltip
      message={getTranslation("TOOLTIP_MESSAGE_EXTERNAL_COMPONENT")}
      innerText={getTranslation("LABEL_EXTERNAL")}
      containerClass=""
    />,
  ];

  useEffect(() => {
    setCurrentComponentsState(components);
    setProcessStep(
      openAI.components?.steps?.filter(
        ({ name }) => !projectData.components.find((componentName) => componentName.name === name),
      ),
    );
  }, [components, openAI]);

  return (
    <div>
      <ProjectTopBar match={match} history={history} currentProjectName={product.name} />

      <div className="components-dashboard-wrapper flex flex-column mt-120px">
        {isLoading && <Loader />}

        {!isLoading && !showNotFoundError && (
          <>
            <ComponentsDashboardHeader
              projectTypeName={product.typeName}
              projectType={product.type}
              inputValues={inputValues}
              isSorted={isSorted}
              setSorted={(e) => setPreference({ attributesSortedAlphabetical: e })}
              compact={compact}
              setCompact={(e) => setPreference({ componentsCompactView: e })}
              componentsCount={projectData.components.length}
              handleChange={handleChange}
              labels={labels}
              sortLabel={sortLabel}
              handleModalOpen={handleModalOpen}
              labelsPerson={labelsPerson}
              isCustomerState={isCustomerState}
              handleChangeSwitch={(e) => setIsCustomerState(e)}
            >
              <div className="flex align-center justify-center">
                <button
                  className="add-attribute-button flex align-center justify-space-around generate-component mb-0 width-70"
                  style={{
                    width: 118,
                    display: "flex",
                    justifyContent: numberOpenAIRequests ? "space-between" : "center",
                  }}
                  onClick={() => {
                    setSuggestingVisible(true);
                    setModalOpen({ ...modalOpen, generateComponents: true });
                    updateHeight();
                  }}
                  disabled={suggestedLoading?.component}
                >
                  <AIGenerateIcon />
                  <span
                    style={numberOpenAIRequests ? {} : { marginLeft: 10 }}
                    className={`flex ${numberOpenAIRequests}`}
                  >
                    {getTranslation(
                      product?.hasAIResponseCached || numberOpenAIRequests ? "GENERATE_DIALOG_TITLE" : "BUY_CREDITS",
                    )}
                  </span>
                </button>
              </div>
            </ComponentsDashboardHeader>

            <div style={{ flex: 1 }}>
              <div className="components-wrapper">
                {hasFilteredData && componentsCards()}
                {!hasFilteredData && components.length > 0 && (
                  <div className="flex-column component-placeholder">
                    <span>{getTranslation("PAGE_COMPONENT_NOT_FOUND")}</span>
                  </div>
                )}
                {!hasFilteredData && !components.length && (
                  <div className="flex-column component-placeholder">
                    <span>
                      {getTranslation(
                        product.type === "process"
                          ? "PAGE_COMPONENT_NO_STEPS_IN_PROJECT_TOP_SPAN"
                          : "PAGE_COMPONENT_NO_COMPONENTS_IN_PROJECT_TOP_SPAN",
                      )}
                    </span>
                    <span>
                      {getTranslation(
                        product.type === "process"
                          ? "PAGE_COMPONENT_NO_STEPS_IN_PROJECT_BOTTOM_SPAN"
                          : "PAGE_COMPONENT_NO_COMPONENTS_IN_PROJECT_BOTTOM_SPAN",
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {paymentInfo.key && (
          <CheckoutModal
            isOpenAISubscription
            closeDialog={() => setPaymentInfo((prev) => ({ ...prev, key: "" }))}
            subscriptionInfo={paymentInfo}
            history={history}
            mode="payment"
            setGeneratedOpenAIRequests={setGeneratedOpenAIRequests}
          />
        )}

        {modalOpen.openBuyModal && (
          <BuyMoreAICreditsModal
            setPaymentInfo={setPaymentInfo}
            closeDialog={() => setModalOpen((prev) => ({ ...prev, openBuyModal: false }))}
          />
        )}

        {generationError && (
          <WarningNotTokensModal
            closeDialog={() => {
              clearGenerationError();
              setModalOpen((prev) => ({ ...prev, generateComponents: false, generateAttributes: false }));
            }}
            openBuyModal={() => setModalOpen((prev) => ({ ...prev, openBuyModal: true }))}
            history={history}
          />
        )}
        {isOpenGenerateComponentsModal && (
          <GenerateComponentsModal
            setLastWordsToGeneratedOpenAIComponents={(name) => {
              setLastWordsToGeneratedOpenAIComponents(name);
              suggestedComponentsNameRef.current = name;
            }}
            projectType={product.type}
            projectTypeName={product.typeName}
            lastGeneratedWordsOpenAI={openAI.words}
            handleCreateSuggested={handleCreateSuggestedComponent}
            onSuccess={() => getComponentsData(projectId)}
            closeDialog={() => {
              setCurrentComponents(components.filter((component) => !component.id.includes("generate")));
              handleCloseModal();
            }}
            teamId={product.teamId}
            productId={projectId}
            setStepsOpenAI={(steps) => setGeneratedOpenAIComponents({ steps })}
            suggestedComponents={[
              openAI.components?.internal?.filter(
                (aName) => !projectData.components.find((componentName) => componentName.name === aName),
              ),
              openAI.components?.external?.filter(
                (aName) => !projectData.components.find((componentName) => componentName.name === aName),
              ),
            ]}
            {...{
              suggestedLoading,
              handleSuggestingComponents,
              setCurrentComponents,
              handleSuggestingSteps,
            }}
            componentsCount={projectData.components.length}
            labelsPerson={labelsPerson}
            isCustomerState={isCustomerState}
            handleChangeSwitch={(e) => setIsCustomerState(e)}
            currentComponentsBackup={components}
            processStepsBackup={openAI.components?.steps?.filter(
              ({ name }) => !projectData.components.find((componentName) => componentName.name === name),
            )}
            currentComponents={currentComponentsState}
            processSteps={processSteps}
            changeProcessSteps={(steps) => setProcessStep(steps)}
            changeCurrentComponents={(components) => setCurrentComponentsState(components)}
          />
        )}
        {isOpenGenerateAttributesModal && (
          <GenerateAttributesModal
            projectTypeName={product.typeName}
            teamId={product.teamId}
            closeDialog={handleCloseModal}
            componentId={selectedElement.componentId}
            internal={selectedElement.internal}
            currentComponent={selectedElement.element}
            onSuccess={() => getComponentsData(projectId)}
            handleCreateSuggested={handleCreateSuggestedAttribute}
            componentName={components.find(({ id }) => componentId === id).name}
            productId={projectId}
            projectType={product.type}
            currentProjectName={product.name}
            suggestedAttributes={
              suggestedAttributes?.filter(
                (aName) =>
                  !projectData.attributes.find((attribute) => {
                    return attribute.name === aName && attribute.componentId === selectedElement.componentId;
                  }),
              ) || []
            }
            {...{ suggestedMode, suggestedLoading }}
          />
        )}

        {modalOpen.attributeCopyModal && (
          <CardModal
            items={attributeMenuItems}
            isOpen={true}
            top={lastClickPosition.clientY - 33}
            left={lastClickPosition.clientX - 75}
            onCancel={closeAttributeCopyModal}
          ></CardModal>
        )}

        {modalOpen.component && (
          <ComponentAddModal
            projectType={product.type}
            edit={editMode}
            closeDialog={handleCloseModal}
            currentComponent={selectedElement.element}
            productId={projectId}
            teamId={product.teamId}
            setModalOpen={setModalOpen}
            handleDeleteComponent={(id) => handleDelete(product.type === "process" ? "step" : "component", id)}
            onSuccess={() => getComponentsData(projectId)}
            {...{ suggestedMode }}
            labelsPerson={labelsPerson}
            labelsLocations={labelsLocations}
            isCustomerState={isCustomerState}
          />
        )}
        {modalOpen.attribute && (
          <AttributeAddModal
            language={language}
            closeDialog={handleCloseModal}
            edit={editMode}
            selectedAttribute={selectedElement}
            componentId={selectedElement.componentId}
            disabled={selectedElement.disabled}
            internal={selectedElement.internal}
            componentName={
              components.find(({ id }) => id === (selectedElement.componentId || selectedElement?.element?.componentId))
                ?.name || ""
            }
            productId={projectId}
            teamId={product.teamId}
            onSuccess={() => getComponentsData(projectId)}
            {...{ suggestedMode }}
          />
        )}
        {modalOpen.delete && (
          <ConfirmationModal
            className="delete-conformation-modal"
            textContainerClass="span-delete-modal"
            closeDialog={handleCloseModal}
            message={getTranslation(
              popupMessages[
                elementToDelete.type !== "attribute" && product.type === "process" ? "step" : elementToDelete.type
              ],
            )}
            autoFocus={true}
            buttonText={getTranslation("EDIT_ATTRIBUTE_DIALOG_BUTTON_DELETE")}
            onConfirm={() => {
              deleteItem(elementToDelete);
            }}
          />
        )}

        {modalOpen.deleteWarning && (
          <WarningModal
            className="warning-delete-modal"
            rightButtonText={getTranslation("WARNING_MODAL_GO_AHEAD_BUTTON_TEXT")}
            leftButtonText={getTranslation("WARNING_MODAL_CANCEL_BUTTON_TEXT")}
            message={getTranslation(
              product.type === "process"
                ? "CONFIRM_MODAL_WARDING_DELETE_STEP"
                : "CONFIRM_MODAL_WARDING_DELETE_COMPONENT",
            )}
            onConfirm={() => {
              deleteItem(elementToDelete);
              setModalOpen({ ...modalOpen, deleteWarning: false });
            }}
            onCancel={() => setModalOpen({ ...modalOpen, deleteWarning: false })}
            closeDialog={() => setModalOpen({ ...modalOpen, deleteWarning: false })}
          />
        )}
        {showNotFoundError && <MessageShowNotFound history={history} />}
      </div>
    </div>
  );
};

ComponentsDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  prop: state,
  auth: state.auth,
  errors: state.project.errors,
  product: state.project.product,
  replacements: state.project.replacements,
  subtractions: state.project.subtractions,
  multiplications: state.project.multiplications,
  detailsMatrix: state.project.detailsMatrix,
  attributes: state.project.attributes,
  components: state.project.components,
  isLoading: state.project.isLoading,
  compact: state.preferences.componentsCompactView,
  isSorted: state.preferences.attributesSortedAlphabetical,
  openAI: state.project.openAI,
  numberOpenAIRequests: state.project.openAI.numberRequests,
  language: state.auth.userInfo.language,
});

const mapDispatchToProps = {
  setGeneratedOpenAIComponents,
  getProjectInfo: getFullProjectInfo,
  setPreference,
  setLastWordsToGeneratedOpenAIComponents,
  setGeneratedOpenAIRequests,
  setCurrentComponents,
  setStepsOpenAI,
  setProjectHasAIResponsesCache,
  setComponentsHasAIResponsesCache,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsDashboard);
