import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { sortableContainer } from "react-sortable-hoc";

import { setPreference } from "../../actions/preferencesActions";
import { getFullProjectInfo } from "../../actions/projectActions";
import {
  copyComponent,
  deleteComponent,
  disableComponent,
  duplicateComponent,
  updateComponent,
} from "../../api/components";
import useContainerHeight from "../../common/useContainerHeight";
import { getTranslation } from "../../helpers/getLanguage";
import { useUpdateSortOrderOfProject } from "../../hooks/projects/useUpdateSortOrderOfProject";
import Loader from "../common/Loader";
import MessageShowNotFound from "../common/MessageShowNotFound";
import ProjectTopBar from "../common/ProjectTopBar";
import CardModal from "../dialogs/CardModal";
import ComponentAddModal from "../dialogs/ComponentAddModal";
import ComponentReplaceModal from "../dialogs/ComponentReplaceModal";
import ConfirmationModal from "../dialogs/ConfirmationModal";
import ReplacementEditModal from "../dialogs/ReplacementEditModal";
import WarningModal from "../dialogs/WarningModal";
import ComponentCard from "./ComponentCard";
import ReplacementHeader from "./ReplacementHeader";
import "./styles.css";

const initialState = {
  search: "",
};

const modalsInitialState = {
  component: false,
  delete: false,
  componentCopyModal: false,
  createIdea: false,
  replacement: false,
};

const projectInitialState = {
  product: {},
  components: [],
};

const popupMessages = {
  component: "CONFIRM_MODAL_DELETE_COMPONENT",
  step: "CONFIRM_MODAL_DELETE_STEP",
};

const initialSortOrder = { components: [] };

const elementTypes = {
  component: "component",
  step: "step",
};

const initialComponentToCopy = { id: "", source: "", destination: "", index: 0 };

const ReplacementMatrix = ({
  match,
  errors,
  product,
  components,
  replacements,
  auth,
  history,
  isLoading,
  isSorted,
  setPreference,
  subtractions,
  multiplications,
  detailsMatrix,
  getProjectInfo: getComponentsData,
}) => {
  const projectId = match.params.projectId;
  const {
    user: { name, lastName, id: userId },
  } = auth;
  const [inputValues, setInputValues] = useState(initialState);
  const [projectData, setProjectData] = useState(projectInitialState);
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [modalOpen, setModalOpen] = useState(modalsInitialState);
  const [selectedReplacementMatrix, setSelectedReplacementMatrix] = useState({});
  const [selectedRemoteElement, setSelectedRemoteElement] = useState({});
  const [elementToDelete, setElementToDelete] = useState();
  const [editMode, setEditMode] = useState(false);
  const containerRef = useRef(null);
  const [containerHeight, updateHeight] = useContainerHeight(containerRef, 20);
  const [sortOrder, setSortOrder] = useState(null);
  const [componentToCopy, setComponentToCopy] = useState(initialComponentToCopy);
  const [lastClickPosition, setLastClickPosition] = useState({ clientX: 0, clientY: 0 });
  const [activeComponent, setActiveComponent] = useState("");
  const [activeComponentInModal, setActiveComponentInModal] = useState({});
  const [pendingComponentCreation, setPendingComponentCreation] = useState(false);
  const [newReplacingComponent, setNewReplacingComponent] = useState({});

  const dragMode = useRef(false);
  const subtractionListsRef = useRef(null);

  const { handleUpdateOrder } = useUpdateSortOrderOfProject({ projectId, teamId: product.teamId });

  useEffect(() => {
    getComponentsData(projectId);
  }, [getComponentsData, projectId]);

  useEffect(() => {
    product.id && sortOrder && updateProjectData(sortOrder);
  }, [sortOrder, product.id]);

  const updateProjectData = (sort) => {
    const { internalComponents, externalComponents } = sortProjectData(
      components.filter((c) => !c.locked),
      sort,
    );

    setProjectData((projectData) => ({
      ...projectData,
      product: product,
      internalComponents,
      externalComponents,
      components: [...internalComponents, ...externalComponents],
    }));
    updateHeight();
  };

  useEffect(() => {
    if (product.id) {
      const sort = product.sortOrder || initialSortOrder;
      setSortOrder({ components: sort.components || [] });
    }
  }, [product]);

  useEffect(() => {
    const filterComponents = () => {
      const searchString = inputValues.search.toLowerCase();

      const filteredComponents = projectData?.components.filter((component) =>
        component.name.toLowerCase().includes(searchString),
      );

      setFilteredComponents([...filteredComponents]);
    };

    if (projectData) {
      if (!inputValues.search) {
        setFilteredComponents([...projectData?.components]);
      } else {
        filterComponents();
      }
    }
  }, [inputValues.search, projectData]);

  const sortProjectData = (unsortedComponents, sort) => {
    const componentsOrder = sort.components || [];
    let clonedComponentsOrder = Array.from(new Set(_.cloneDeep(componentsOrder || [])));
    const newSortOrder = _.cloneDeep(sort);

    let shouldUpdate = false;

    let internalComponents, externalComponents;
    let components = unsortedComponents.slice();

    if (clonedComponentsOrder.length) {
      const newComponentsOrder = [];

      const internalClonedComponents = components.filter((component) => component.internal && !component.locked);
      const externalClonedComponents = components.filter((component) => !component.internal && !component.locked);

      const updateComponentsOrder = (baseOffset = 0, components) => {
        let offset = 0;
        for (let index in clonedComponentsOrder) {
          const currentOrder = clonedComponentsOrder[index];
          const foundComponent = components.find((component) => component.id === currentOrder);
          if (foundComponent) {
            foundComponent.sortOrder = +index - offset + baseOffset;
            newComponentsOrder.push(foundComponent.id);
          } else {
            offset++;
          }
        }
      };

      const updateUnorderedComponents = (isInternal) => {
        let unorderedComponent = components.find(
          (component) => typeof component.sortOrder !== "number" && component.internal === isInternal,
        );

        while (unorderedComponent) {
          unorderedComponent.sortOrder = newComponentsOrder.length;
          newComponentsOrder.push(unorderedComponent.id);

          unorderedComponent = components.find(
            (component) => typeof component.sortOrder !== "number" && component.internal === isInternal,
          );
        }
      };

      updateComponentsOrder(newComponentsOrder.length, internalClonedComponents);
      updateUnorderedComponents(true);

      updateComponentsOrder(newComponentsOrder.length, externalClonedComponents);
      updateUnorderedComponents(false);

      const isComponentsOrderChanged = !_.isEqual(newComponentsOrder, sortOrder.components);
      if (isComponentsOrderChanged) {
        newSortOrder.components = newComponentsOrder;
        clonedComponentsOrder = newComponentsOrder;
        shouldUpdate = true;
      }

      components.sort((a, b) => a.sortOrder - b.sortOrder);

      internalComponents = components.filter((component) => component.internal);
      externalComponents = components.filter((component) => !component.internal);
    } else {
      if (unsortedComponents.length) {
        const lockedInternalComponent = unsortedComponents.find((component) => component.locked && component.internal);
        const lockedExternalComponent = unsortedComponents.find((component) => component.locked && !component.internal);

        components = components.filter((component) => !component.locked).sort((a, b) => (a.date > b.date ? 1 : -1));

        internalComponents = components.filter((component) => component.internal);
        externalComponents = components.filter((component) => !component.internal);

        lockedInternalComponent && internalComponents.unshift(lockedInternalComponent);
        lockedExternalComponent && externalComponents.unshift(lockedExternalComponent);

        clonedComponentsOrder = [...internalComponents, ...externalComponents].map((component) => component.id);

        newSortOrder.components = clonedComponentsOrder;
        shouldUpdate = true;
      } else {
        newSortOrder.components = [];

        internalComponents = [];
        externalComponents = [];
      }
    }

    shouldUpdate && handleUpdateOrder(clonedComponentsOrder);
    !_.isEqual(newSortOrder, sortOrder) && setSortOrder(newSortOrder);

    return {
      internalComponents,
      externalComponents,
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleCloseModal = (shouldUpdateData) => {
    shouldUpdateData && getComponentsData(projectId);
    setEditMode(false);
    setPendingComponentCreation(false);
    setSelectedReplacementMatrix({});
    setSelectedRemoteElement({});
    setActiveComponentInModal({});
    setNewReplacingComponent({});
    setModalOpen({
      component: false,
      delete: false,
      edit: false,
      replacement: false,
      createIdea: false,
    });
  };

  const handleModalOpen = (name, remoteElement, edit = false, detailsId, componentId) => {
    remoteElement && setSelectedRemoteElement(remoteElement);
    edit && setEditMode(true);

    componentId && setActiveComponent(componentId);

    const selReplacementsMatrix = replacements.find((subMatrix) => subMatrix.id === detailsId);
    selReplacementsMatrix && setSelectedReplacementMatrix(selReplacementsMatrix);

    setModalOpen({ ...modalOpen, [name]: true });
  };

  const handleOpenAddComponentAndCloseReplacement = () => {
    setModalOpen({ ...modalOpen, component: true, replacement: false });
  };

  const handleOpenIdeaModalAndCloseReplacement = () => {
    setModalOpen({ ...modalOpen, createIdea: true, replacement: false });
  };

  const handleDelete = (type, id) => {
    const ideals = [...replacements, ...subtractions, ...multiplications, ...detailsMatrix];
    setElementToDelete({ type, id });

    if (
      ideals.some((idea) => {
        let componentId = null;

        for (let key in idea) {
          if (/id+[-.\w]+Component/g.test(key)) {
            componentId = idea[key];
            break;
          }
        }

        return id === componentId;
      })
    ) {
      return setModalOpen({ ...modalOpen, deleteWarning: true });
    }

    setModalOpen({ ...modalOpen, delete: true });
  };

  const typeExists = (type) => {
    const isPresent = Object.values(elementTypes).includes(type);

    if (!isPresent) {
      console.warn("Cannot find the type ", type);
    }

    return isPresent;
  };

  const deleteItem = (element) => {
    const { type } = element;
    if (!typeExists(type)) {
      return;
    }

    const params = { id: element.id, teamId: product.teamId || "" };

    deleteComponent(params, () => getComponentsData(projectId));

    setModalOpen({ ...modalOpen, delete: false });

    setElementToDelete(null);
  };

  const handleDuplicate = (type, element) => {
    if (!typeExists(type)) {
      return;
    }

    const params = {
      id: element.id,
      userId: auth.user.id,
      teamId: product.teamId || "",
    };

    if (type === elementTypes.component) {
      duplicateComponent(params, () => getComponentsData(projectId));
    }
  };

  const handleDisable = (type, element) => {
    if (!typeExists(type)) {
      return;
    }

    const params = { id: element.id, disabled: !element.disabled, teamId: product.teamId || "" };

    if (type === elementTypes.component) {
      disableComponent(params, () => getComponentsData(projectId));
    }
  };

  const containerStyle = {};
  containerHeight && (containerStyle.height = containerHeight);

  const showNotFoundError = errors && errors.response && errors.response.status === 404;

  const handleCopyComponent = () => {
    const { destination, id } = componentToCopy;

    const params = {
      id,
      projectId,
      teamId: product.teamId,
      internal: destination === "internal",
    };

    const onSuccess = () => {
      setComponentToCopy(initialComponentToCopy);
      getComponentsData(projectId);
      setModalOpen({ ...modalOpen, componentCopyModal: false });
    };

    const onError = (error) => {
      console.error(error);
      setComponentToCopy(initialComponentToCopy);
      setModalOpen({ ...modalOpen, componentCopyModal: false });
    };

    copyComponent(params, onSuccess, onError);
  };

  const handleMoveComponent = () => {
    const params = {
      id: componentToCopy.id,
      name: componentToCopy.name,
      internal: !componentToCopy.internal,
      importance: componentToCopy.importance,
      description: componentToCopy.description,
      disabled: componentToCopy.disabled,
      teamId: projectData.product.teamId || "",
    };

    const onSuccess = () => {
      setModalOpen({ ...modalOpen, componentCopyModal: false });
      getComponentsData(projectId);
    };

    const onError = (error) => {
      console.error(error);
    };

    updateComponent(params, onSuccess, onError);
  };

  const attributeMenuItems = [
    {
      value: "PAGE_SUBTRACTION_COMPONENT_MENU_ITEM_COPY",
      action: () => handleCopyComponent(),
    },
    {
      value: "PAGE_SUBTRACTION_COMPONENT_MENU_ITEM_MOVE",
      action: () => handleMoveComponent(),
    },
  ];

  const handleChooseComponent = (component) => {
    setActiveComponentInModal(component);
  };

  const closeAttributeCopyModal = useCallback(() => {
    setModalOpen((modalOpen) => ({ ...modalOpen, componentCopyModal: false }));
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragStart = () => {
    dragMode.current = true;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!result.destination) {
      return;
    }
    const isSameComponent = destination.droppableId === source.droppableId;
    const isSameIndex = destination.index === source.index;

    if (isSameComponent && isSameIndex) {
      return;
    }

    if (isSameComponent && !isSameIndex) {
      const internalIds = sortOrder.components.slice(0, projectData.internalComponents.length);
      const externalIds = sortOrder.components.slice(projectData.internalComponents.length);

      if (source.droppableId === "external") {
        const reorderedComponentIds = reorder(externalIds || [], source.index, destination.index);
        const newOrder = [...internalIds, ...reorderedComponentIds];

        updateProjectData({
          ...sortOrder,
          components: newOrder,
        });
      } else if (source.droppableId === "internal") {
        const reorderedComponentIds = reorder(internalIds || [], source.index, destination.index);
        const newOrder = [...reorderedComponentIds, ...externalIds];

        updateProjectData({
          ...sortOrder,
          components: newOrder,
        });
      }
    }

    if (!isSameComponent) {
      const component = components.find((component) => component.id === result.draggableId);

      setComponentToCopy({
        source: source.droppableId,
        destination: destination.droppableId,
        index: destination.index,
        ...component,
      });

      const elem = document.querySelector(`[data-rbd-droppable-id="${destination.droppableId}"]`);
      const rect = elem.parentElement.getBoundingClientRect();
      const position = { left: rect.x + rect.width / 2, top: rect.y + rect.height / 2 };
      if (position.top < 0) {
        position.top = 75;
      }
      if (position.top > window.innerHeight) {
        position.top = window.innerHeight - 75;
      }

      setLastClickPosition({ clientX: position.left, clientY: position.top });

      setModalOpen({ ...modalOpen, componentCopyModal: true });
    }

    dragMode.current = false;
  };

  const SortableCards = sortableContainer(({ children }) => {
    return (
      <div ref={subtractionListsRef} className="flex width-100 height-100 flex-wrap">
        {children}
      </div>
    );
  });

  const passNewComponent = (response) => {
    getComponentsData(projectId);

    if (!pendingComponentCreation) {
      return;
    }

    setActiveComponentInModal({});

    setFilteredComponents([...filteredComponents, response]);
    handleChooseComponent(response);
    setPendingComponentCreation(false);

    setEditMode(false);
    setModalOpen({
      component: false,
      delete: false,
      edit: false,
      replacement: true,
      createIdea: false,
    });
  };

  const getReplacingComponent = () => {
    return activeComponentInModal?.id
      ? activeComponentInModal
      : newReplacingComponent?.id
        ? newReplacingComponent
        : components.find((component) => component.id === selectedReplacementMatrix.idReplacingComponent);
  };

  return (
    <div>
      <ProjectTopBar match={match} history={history} currentProjectName={product.name} />

      <div className="components-dashboard-wrapper">
        {isLoading && <Loader />}

        {!isLoading && !showNotFoundError && (
          <>
            <ReplacementHeader
              projectType={product.type}
              isSorted={isSorted}
              sortLabel={
                product.type === "process"
                  ? "PAGE_SUBTRACTION_SORT_LABEL_STEPS"
                  : "PAGE_SUBTRACTION_SORT_LABEL_COMPONENTS"
              }
              inputValues={inputValues}
              handleChange={handleChange}
              setSorted={(e) => setPreference({ componentsSortedAlphabetical: e })}
            />
            <SortableCards>
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <ComponentCard
                  key={1}
                  isProcessType={product.type === "process"}
                  projectType={product.type}
                  internal={true}
                  isSorted={isSorted}
                  inputValues={inputValues}
                  handleDelete={(id) => handleDelete(product.type === "process" ? "step" : "component", id)}
                  handleDisable={handleDisable}
                  handleModalOpen={handleModalOpen}
                  handleDuplicate={handleDuplicate}
                  activeComponent={activeComponent}
                  isModalOpen={modalOpen["replacement"] || modalOpen["createIdea"] || modalOpen["component"]}
                  replacementsMatrixList={replacements}
                  filteredComponents={filteredComponents}
                  setActiveComponent={setActiveComponent}
                  subtractionListsRef={subtractionListsRef}
                />

                {product.type !== "process" && (
                  <ComponentCard
                    key={2}
                    isProcessType={product.type === "process"}
                    projectType={product.type}
                    internal={false}
                    isSorted={isSorted}
                    inputValues={inputValues}
                    handleDelete={(id) => handleDelete(product.type === "process" ? "step" : "component", id)}
                    handleDisable={handleDisable}
                    handleDuplicate={handleDuplicate}
                    handleModalOpen={handleModalOpen}
                    activeComponent={activeComponent}
                    isModalOpen={modalOpen["replacement"] || modalOpen["createIdea"] || modalOpen["component"]}
                    replacementsMatrixList={replacements}
                    filteredComponents={filteredComponents}
                    setActiveComponent={setActiveComponent}
                    subtractionListsRef={subtractionListsRef}
                  />
                )}
              </DragDropContext>
            </SortableCards>
          </>
        )}

        {modalOpen.componentCopyModal && (
          <CardModal
            items={attributeMenuItems}
            isOpen={true}
            top={lastClickPosition.clientY - 33}
            left={lastClickPosition.clientX - 75}
            onCancel={closeAttributeCopyModal}
          ></CardModal>
        )}

        {modalOpen.component && (
          <ComponentAddModal
            projectType={product.type}
            edit={editMode}
            closeDialog={handleCloseModal}
            currentComponent={selectedRemoteElement.element}
            productId={projectId}
            teamId={product.teamId}
            onSuccess={passNewComponent}
            pendingComponentCreation={pendingComponentCreation}
            setModalOpen={setModalOpen}
            handleDeleteComponent={(id) => handleDelete(product.type === "process" ? "step" : "component", id)}
          />
        )}

        {modalOpen.delete && (
          <ConfirmationModal
            className="delete-conformation-modal"
            textContainerClass="span-delete-modal"
            closeDialog={handleCloseModal}
            message={getTranslation(popupMessages[elementToDelete.type])}
            autoFocus={true}
            buttonText={getTranslation("EDIT_ATTRIBUTE_DIALOG_BUTTON_DELETE")}
            onConfirm={() => deleteItem(elementToDelete)}
          />
        )}

        {modalOpen.deleteWarning && (
          <WarningModal
            className="warning-delete-modal"
            rightButtonText={getTranslation("WARNING_MODAL_GO_AHEAD_BUTTON_TEXT")}
            leftButtonText={getTranslation("WARNING_MODAL_CANCEL_BUTTON_TEXT")}
            message={getTranslation(
              product.type === "process"
                ? "CONFIRM_MODAL_WARDING_DELETE_STEP"
                : "CONFIRM_MODAL_WARDING_DELETE_COMPONENT",
            )}
            onConfirm={() => {
              deleteItem(elementToDelete);
              setModalOpen({ ...modalOpen, deleteWarning: false });
            }}
            onCancel={() => setModalOpen({ ...modalOpen, deleteWarning: false })}
            closeDialog={() => setModalOpen({ ...modalOpen, deleteWarning: false })}
          />
        )}

        {modalOpen.replacement && (
          <ComponentReplaceModal
            projectType={product.type}
            components={filteredComponents}
            currentComponentId={activeComponent}
            closeDialog={handleCloseModal}
            activeComponent={activeComponentInModal}
            handleChooseComponent={handleChooseComponent}
            handleOpenAddComponentAndCloseReplacement={handleOpenAddComponentAndCloseReplacement}
            handleOpenIdeaModalAndCloseReplacement={handleOpenIdeaModalAndCloseReplacement}
            setPendingComponentCreation={setPendingComponentCreation}
          />
        )}
        {modalOpen.createIdea && (
          <ReplacementEditModal
            closeDialog={handleCloseModal}
            userFullName={{ name, lastName }}
            userId={userId}
            edit={editMode}
            remoteComponent={selectedRemoteElement}
            replacingComponent={getReplacingComponent()}
            project={projectData}
            productId={projectId}
            replacementMatrix={selectedReplacementMatrix}
            teamId={product.teamId}
            categories={product.customCategories}
            productType={product.type}
            productName={product.typeName}
          />
        )}

        {showNotFoundError && <MessageShowNotFound history={history} />}
      </div>
    </div>
  );
};

ReplacementMatrix.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  prop: state,
  auth: state.auth,
  errors: state.project.errors,
  product: state.project.product,
  replacements: state.project.replacements,
  components: state.project.components,
  isLoading: state.project.isLoading,
  isSorted: state.preferences.componentsSortedAlphabetical,
  subtractions: state.project.subtractions,
  multiplications: state.project.multiplications,
  detailsMatrix: state.project.detailsMatrix,
});

export default connect(mapStateToProps, { getProjectInfo: getFullProjectInfo, setPreference })(ReplacementMatrix);
